<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Viaje</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Viaje</li>
                  <li class="breadcrumb-item active">Editar</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Viaje {{ id }}</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    class="nav-item"
                    v-if="$store.getters.can('tep.viajes.edit')"
                  >
                    <a
                      class="nav-link active"
                      id="tab-Detalles"
                      data-toggle="tab"
                      href="#Detalles"
                      >Detalles</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="$store.getters.can('tep.viajes.edit')"
                  >
                    <a
                      class="nav-link"
                      id="tab-Pasajeros"
                      data-toggle="tab"
                      href="#Pasajeros"
                      >Pasajeros</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="$store.getters.can('tep.viajes.edit')"
                  >
                    <a
                      class="nav-link"
                      id="tab-Presupuesto"
                      data-toggle="tab"
                      href="#Presupuesto"
                      >Presupuesto</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="$store.getters.can('tep.viajes.editFechas')"
                  >
                    <a
                      class="nav-link"
                      id="tab-Fechas"
                      data-toggle="tab"
                      href="#Fechas"
                      >Fechas</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    id="Detalles"
                    class="tab-pane fade active show"
                    v-if="$store.getters.can('tep.viajes.edit')"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-5">
                          <label>Transportadora</label>
                          <v-select
                            :class="[
                              $v.form.detalles.transportadora.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.detalles.transportadora"
                            placeholder="Transportadora"
                            label="razon_social"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.transportadoras"
                            :filterable="true"
                            @input="changeEmpresa"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-5">
                          <label>Ruta</label>
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="form.detalles.ruta"
                            placeholder="Ruta"
                            label="nombre"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.rutas"
                            :filterable="true"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-2">
                          <label>Vehículo</label>
                          <v-select
                            :class="[
                              $v.form.detalles.vehiculo.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.detalles.vehiculo"
                            placeholder="Vehículo"
                            label="placa"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.vehiculos"
                            :filterable="true"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-6">
                          <label>Conductor</label>
                          <v-select
                            :class="[
                              $v.form.detalles.conductor.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.detalles.conductor"
                            placeholder="Conductor"
                            label="nConductor"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.conductores"
                            :filterable="true"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Dirección Origen</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.detalles.direccion_origen"
                            :class="
                              $v.form.detalles.direccion_origen.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label>Dirección Destino</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.detalles.direccion_destino"
                            :class="
                              $v.form.detalles.direccion_destino.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label>Fecha Inicio Servicio Usuario</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.detalles.fecha_ini_app_user"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_ini_app_user.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioUsuario"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.detalles.hora_ini_app_user"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_ini_app_user.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioUsuario"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Fecha Fin Servicio Usuario</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.detalles.fecha_fin_app_user"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_fin_app_user.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioUsuario"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.detalles.hora_fin_app_user"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_fin_app_user.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioUsuario"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Fecha Inicio Servicio Conductor</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.detalles.fecha_ini_app_conductor"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_ini_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioConductor"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.detalles.hora_ini_app_conductor"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_ini_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioConductor"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Fecha Fin Servicio Conductor</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.detalles.fecha_fin_app_conductor"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_fin_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioConductor"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.detalles.hora_fin_app_conductor"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_fin_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioConductor"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Llegada Conductor</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="
                                  form.detalles.fecha_llegada_app_conductor
                                "
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_llegada_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="
                                  form.detalles.hora_llegada_app_conductor
                                "
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_llegada_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-12 text-right">
                          <button
                            class="btn bg-success"
                            v-if="
                              !$v.form.detalles.$invalid &&
                                $store.getters.can('tep.viajes.edit')
                            "
                            @click="save('detalles')"
                          >
                            <i class="fas fa-save"></i> Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="Pasajeros"
                    class="tab-pane fade"
                    v-if="$store.getters.can('tep.viajes.edit')"
                  >
                    <div class="card-body pb-0">
                      <div class="row">
                        <div class="form-group col-md-10">
                          <label>Pasajeros</label>
                          <v-select
                            :class="[
                              $v.form.pasajeros.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.pasajeros"
                            placeholder="Pasajeros"
                            label="documento"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.pasajeros"
                            :filterable="true"
                            multiple
                          ></v-select>
                        </div>
                        <div class="form-group col-md-2">
                          <button
                            class="btn bg-success mt-4"
                            v-if="
                              !$v.form.pasajeros.$invalid &&
                                $store.getters.can('tep.viajes.edit')
                            "
                            @click="save('pasajeros')"
                          >
                            <i class="fas fa-save"></i> Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body pb-0 table-responsive">
                      <div class="row">
                        <div class="col-md-12">
                          <h5>Pasajeros Solicitud</h5>
                        </div>
                        <div class="form-group col-md-12">
                          <table
                            id="tabla-pasajeros"
                            class="table table-bordered table-striped table-hover table-condensed table-sm text-nowrap"
                          >
                            <thead>
                              <tr class="text-center">
                                <th>
                                  <div class="btn-group">
                                    <button
                                      type="button"
                                      class="btn bg-primary btn-sm"
                                      @click="addFormPasajero"
                                      :disabled="verFormPasajeros"
                                    >
                                      <i class="fas fa-plus"></i>
                                    </button>
                                  </div>
                                </th>
                                <th>Documento</th>
                                <th>Nombre</th>
                                <th>Teléfono</th>
                                <th>Email</th>
                                <th>Empresa</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(pasaj, index) in listasForms.pasajeros"
                                :key="index"
                              >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                  {{ pasaj.documento }}
                                </td>
                                <td>{{ pasaj.nombre }}</td>
                                <td class="text-center">
                                  {{ pasaj.telefono }}
                                </td>
                                <td>{{ pasaj.email }}</td>
                                <td>{{ pasaj.empresa }}</td>
                              </tr>
                              <tr v-if="verFormPasajeros">
                                <td>
                                  <div class="btn-group">
                                    <button
                                      type="button"
                                      class="btn bg-navy btn-sm"
                                      v-if="
                                        !$v.formPasajeros.$invalid &&
                                          $store.getters.can('tep.viajes.edit')
                                      "
                                      @click="addPasajero"
                                    >
                                      <i class="fas fa-save"></i>
                                    </button>
                                    <button
                                      type="button"
                                      class="btn bg-danger btn-sm"
                                      @click="deleteFormPasajero"
                                    >
                                      <i class="fas fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    :class="
                                      $v.formPasajeros.documento.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    v-model="formPasajeros.documento"
                                    @change="validaFuncionario"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    :class="
                                      $v.formPasajeros.nombre.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    v-model="formPasajeros.nombre"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    class="form-control form-control-sm"
                                    :class="
                                      $v.formPasajeros.telefono.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    v-model="formPasajeros.telefono"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    :class="
                                      $v.formPasajeros.email.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    v-model="formPasajeros.email"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    :class="
                                      $v.formPasajeros.empresa.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                    v-model="formPasajeros.empresa"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="Presupuesto"
                    class="tab-pane fade"
                    v-if="$store.getters.can('tep.viajes.edit')"
                  >
                    <div class="card-body pb-0">
                      <div class="row">
                        <div class="form-group col-md-10">
                          <label>Ceco Afe</label>
                          <v-select
                            :class="[
                              $v.form.presupuesto.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.presupuesto"
                            placeholder="Ceco Afe"
                            label="numero"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.cecos_afes"
                            :filterable="true"
                            multiple
                            @input="calcularCecoAfeItinerario()"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-2">
                          <button
                            class="btn bg-success mt-4"
                            v-if="
                              !$v.form.presupuesto.$invalid &&
                                total_porcentaje_itin == 100 &&
                                $store.getters.can('tep.viajes.edit')
                            "
                            @click="save('presupuesto')"
                          >
                            <i class="fas fa-save"></i> Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body pb-0">
                      <div class="row">
                        <div
                          class="col-md-12 mb-0 text-center"
                          v-if="form.presupuesto.length > 0"
                          :class="
                            total_porcentaje_itin == 100
                              ? 'text-success'
                              : total_porcentaje_itin < 100
                              ? 'text-muted'
                              : 'text-danger'
                          "
                        >
                          <h4>
                            Porcentaje Total: {{ total_porcentaje_itin }}%
                          </h4>
                        </div>
                        <div
                          class="form-group col-md-12"
                          v-if="form.presupuesto.length > 0"
                        >
                          <table
                            id="tabla-pasajeros"
                            class="
                              table
                              table-bordered
                              table-striped
                              table-hover
                              table-condensed
                              table-sm
                            "
                          >
                            <thead>
                              <tr>
                                <th class="text-center">Número</th>
                                <th class="text-center">Responsable</th>
                                <th class="text-center">Tipo Presupuesto</th>
                                <th class="text-center">Porcentaje</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(cecoAfe, index) in form.presupuesto"
                                :key="index"
                              >
                                <td class="text-center">
                                  {{ cecoAfe.numero }}
                                </td>
                                <td class="text-nowrap">
                                  {{
                                    cecoAfe.funcionario
                                      ? `${cecoAfe.funcionario.nombres} ${cecoAfe.funcionario.apellidos}`
                                      : ""
                                  }}
                                </td>
                                <td class="text-center">
                                  <div v-if="cecoAfe.tipo_presupuesto == 1">
                                    <h5>
                                      <span class="badge badge-warning"
                                        >Afe</span
                                      >
                                    </h5>
                                  </div>
                                  <div
                                    v-else-if="cecoAfe.tipo_presupuesto == 2"
                                  >
                                    <h5>
                                      <span class="badge badge-success"
                                        >Ceco</span
                                      >
                                    </h5>
                                  </div>
                                </td>
                                <td class="p-1">
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      class="form-control form-control-sm"
                                      v-model="
                                        form.presupuesto[index].porcentaje
                                      "
                                      @blur="
                                        validaPorcentajeCecoAfeItinerario(index)
                                      "
                                    />
                                    <div class="input-group-append">
                                      <span
                                        class="input-group-text form-control-sm"
                                        >%</span
                                      >
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="Fechas"
                    class="tab-pane fade"
                    v-if="$store.getters.can('tep.viajes.editFechas')"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-5">
                          <label for="fecha_ini">Fecha y Hora Inicial</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.fechas.fecha_ini"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fechas.fecha_ini.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasViaje"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.fechas.hora_ini"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fechas.hora_ini.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasViaje"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-5">
                          <label for="fecha_fin">Fecha y Hora Final</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.fechas.fecha_fin"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fechas.fecha_fin.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasViaje"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.fechas.hora_fin"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fechas.hora_fin.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasViaje"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-2">
                          <button
                            class="btn bg-success mt-4"
                            v-if="
                              !$v.form.fechas.$invalid &&
                                $store.getters.can('tep.viajes.editFechas')
                            "
                            @click="save('fechas')"
                          >
                            <i class="fas fa-save"></i> Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "TepViajeEdit",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      id: null,
      accion: null,
      viaje: null,
      total_porcentaje_itin: 0,
      cecosAfesItin: [],
      form: {
        detalles: [],
        pasajeros: [],
        presupuesto: [],
        fechas: [],
      },
      listasForms: {
        cecos_afes: [],
        pasajeros: [],
        transportadoras: [],
        conductores: [],
        vehiculos: [],
        rutas: [],
      },
      verFormPasajeros: false,
      formPasajeros: {
        tep_itinerario_id: null,
        nombre: null,
        documento: null,
        email: null,
        telefono: null,
        empresa: null,
      },
    };
  },
  validations() {
    return {
      form: {
        detalles: {
          transportadora: { required },
          conductor: { required },
          vehiculo: { required },
          direccion_origen: { required },
          direccion_destino: { required },
          fecha_ini_app_user: { required },
          hora_ini_app_user: { required },
          fecha_fin_app_user: { required },
          hora_fin_app_user: { required },
          fecha_ini_app_conductor: { required },
          hora_ini_app_conductor: { required },
          fecha_fin_app_conductor: { required },
          hora_fin_app_conductor: { required },
          fecha_llegada_app_conductor: { required },
          hora_llegada_app_conductor: { required },
        },
        pasajeros: { required },
        presupuesto: { required },
        fechas: {
          fecha_ini: { required },
          hora_ini: { required },
          fecha_fin: { required },
          hora_fin: { required },
        },
      },
      formPasajeros: {
        tep_itinerario_id: { required },
        nombre: { required },
        documento: { required },
        email: { required, email },
        telefono: { required },
        empresa: { required },
      },
    };
  },
  methods: {
    init() {
      this.id = this.$route.params.id;
      this.accion = this.$route.params.accion;
      axios
        .get("/api/tep/viajes/edit", {
          params: { id: this.id },
        })
        .then((response) => {
          this.viaje = response.data;
          // Métodos para llenar las listas para los fomularios
          this.getListCecosAfes();
          this.getListPasajeros();

          // Métodos para llenar los formularios
          this.getFormData();
          this.cargando = false;
        });
      /*  .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }) */
    },

    // Métodos para llenar las listas de los formularios
    getListCecosAfes() {
      this.listasForms.cecos_afes = [];
      if (this.viaje.itinerario && this.viaje.solicitudes) {
        this.viaje.solicitudes.ceco_afe.forEach((ceaf) => {
          const presupuesto = this.viaje.itinerario.cecos_afes.find(
            (ca) => ca.tep_solicitud_presupuesto_id === ceaf.id
          );
          if (presupuesto) {          
            this.listasForms.cecos_afes.push({
              id: ceaf.id,
              ceco_afe_id: ceaf.ceco_afe_id,
              tipo_presupuesto: ceaf.tipo_presupuesto,
              funcionario: ceaf.funcionario ? ceaf.funcionario : null,
              numero: ceaf.numero,
              porcentaje: ceaf.porcentaje,
            });
          } else {
            const presupuesto2 = this.viaje.itinerario.cecos_afes.find(
              (ca) => ca.ceco_afe_id === ceaf.ceco_afe_id
            );
            if (presupuesto2) {              
              this.listasForms.cecos_afes.push({
                id: ceaf.id,
                ceco_afe_id: ceaf.ceco_afe_id,
                tipo_presupuesto: ceaf.tipo_presupuesto,
                funcionario: ceaf.funcionario ? ceaf.funcionario : null,
                numero: ceaf.numero,
                porcentaje: ceaf.porcentaje,
              });
            }
          }
        });
      }
    },

    getListPasajeros() {
      this.listasForms.pasajeros = [];
      if (this.viaje.itinerario && this.viaje.solicitudes) {
        this.viaje.solicitudes.tep_solicitud_pasajero.forEach((pasaj) => {
          this.listasForms.pasajeros.push({
            id: this.getItinPasajeroId(pasaj.numero_documento),
            nombre: pasaj.nombres,
            documento: pasaj.numero_documento,
            email: pasaj.correo,
            telefono: pasaj.telefono,
            empresa: pasaj.empresa_id,
          });
        });
      }
    },

    getItinPasajeroId(documento) {
      const itin_pasajero = this.viaje.itinerario.pasajeros.find(
        (pasaj) => pasaj.documento === documento
      );
      return itin_pasajero ? itin_pasajero.id : null;
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/listaTep").then((response) => {
        this.listasForms.transportadoras = response.data;
        this.getRutas();
      });
    },

    async getConductores() {
      await axios
        .get("/api/admin/conductores/lista", {
          params: { linea_negocio_id: 5 },
        })
        .then((response) => {
          this.listasForms.conductores = response.data;
        });
    },

    async getVehiculos() {
      await axios
        .get("/api/admin/vehiculos/lista", {
          params: { linea_negocio_id: 5 },
        })
        .then((response) => {
          this.listasForms.vehiculos = response.data;
        });
    },

    changeEmpresa() {
      this.form.detalles.ruta = [];
      this.getRutas();
    },

    getRutas() {
      this.listasForms.rutas = [];
      axios
        .get("/api/tep/Ruta/lista", {
          params: { empresa_id: this.form.detalles.transportadora.id },
        })
        .then((response) => {
          this.listasForms.rutas = response.data;
        });
    },

    // Métodos para los formularios
    async getFormData() {
      // Formulario de detalles
      let transportadora = null;
      if (this.viaje.transportadora) {
        transportadora = {
          id: this.viaje.transportadora.id,
          razon_social: this.viaje.transportadora.razon_social,
        };
      }

      let ruta = null;
      if (this.viaje.ruta) {
        ruta = {
          id: this.viaje.ruta.id,
          nombre: this.viaje.ruta.nombre,
        };
      }

      let conductor = null;
      if (this.viaje.conductor) {
        conductor = {
          id: this.viaje.conductor.id,
          nConductor: `${this.viaje.conductor.numero_documento} - ${this.viaje.conductor.nombres} ${this.viaje.conductor.apellidos}`,
        };
      }

      let vehiculo = null;
      if (this.viaje.vehiculo) {
        vehiculo = {
          id: this.viaje.vehiculo.id,
          placa: this.viaje.vehiculo.placa,
        };
      }

      this.form.detalles = {
        transportadora,
        ruta,
        conductor,
        vehiculo,
        direccion_origen: this.viaje.direccion_origen,
        direccion_destino: this.viaje.direccion_destino,
        fecha_ini_app_user: this.viaje.tep_fechas_viajes[0].fecha_ini_app_user
          ? this.viaje.tep_fechas_viajes[0].fecha_ini_app_user.slice(0, 10)
          : "",
        hora_ini_app_user: this.viaje.tep_fechas_viajes[0].fecha_ini_app_user
          ? this.viaje.tep_fechas_viajes[0].fecha_ini_app_user.slice(11, 19)
          : "",
        fecha_fin_app_user: this.viaje.tep_fechas_viajes[0].fecha_fin_app_user
          ? this.viaje.tep_fechas_viajes[0].fecha_fin_app_user.slice(0, 10)
          : "",
        hora_fin_app_user: this.viaje.tep_fechas_viajes[0].fecha_fin_app_user
          ? this.viaje.tep_fechas_viajes[0].fecha_fin_app_user.slice(11, 19)
          : "",
        fecha_ini_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_ini_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_ini_app_conductor.slice(0, 10)
          : "",
        hora_ini_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_ini_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_ini_app_conductor.slice(
              11,
              19
            )
          : "",
        fecha_fin_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_fin_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_fin_app_conductor.slice(0, 10)
          : "",
        hora_fin_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_fin_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_fin_app_conductor.slice(
              11,
              19
            )
          : "",
        fecha_llegada_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_llegada_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_llegada_app_conductor.slice(
              0,
              10
            )
          : "",
        hora_llegada_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_llegada_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_llegada_app_conductor.slice(
              11,
              19
            )
          : "",
      };

      if (this.viaje.itinerario) {
        // Formulario de pasajeros
        this.form.pasajeros = this.viaje.itinerario.pasajeros;

        // Formulario de presupuesto
        this.form.presupuesto = [];
        this.viaje.itinerario.cecos_afes.forEach((itin_ceaf) => {
          let presupuesto = null;
          if (this.viaje.solicitudes) {
            presupuesto = this.viaje.solicitudes.ceco_afe.find(
              (ceaf) => ceaf.id == itin_ceaf.tep_solicitud_presupuesto_id
            );
          }
          if (presupuesto) {
            this.form.presupuesto.push({
              id: presupuesto.id,
              ceco_afe_id: presupuesto.ceco_afe_id,
              tipo_presupuesto: presupuesto.tipo_presupuesto,
              funcionario: presupuesto.funcionario,
              numero: presupuesto.numero,
              porcentaje: itin_ceaf.porcentaje
                ? itin_ceaf.porcentaje
                : presupuesto.porcentaje,
            });
          } else {
            /* const presupuesto2 = this.viaje.solicitudes.ceco_afe.find(
              (ceaf) => ceaf.ceco_afe_id == itin_ceaf.ceco_afe_id
            ); */

            this.form.presupuesto.push({
              id: itin_ceaf.id,
              ceco_afe_id: itin_ceaf.ceco_afe_id,
              tipo_presupuesto: itin_ceaf.ceco_afe.tipo_presupuesto,
              funcionario: null,
              numero: itin_ceaf.ceco_afe.codigo_contable,
              porcentaje: itin_ceaf.porcentaje,
            });
          }
        });
        this.calcularCecoAfeItinerario();
      }

      // Formulario de fechas
      this.form.fechas = {
        fecha_ini: this.viaje.fecha_ini
          ? this.viaje.fecha_ini.slice(0, 10)
          : "",
        hora_ini: this.viaje.fecha_ini
          ? this.viaje.fecha_ini.slice(11, 19)
          : "",
        fecha_fin: this.viaje.fecha_fin
          ? this.viaje.fecha_fin.slice(0, 10)
          : "",
        hora_fin: this.viaje.fecha_fin
          ? this.viaje.fecha_fin.slice(11, 19)
          : "",
      };

      // Funciones de llenado de listas para los formularios
      await this.getEmpresas();
      await this.getConductores();
      await this.getVehiculos();
    },

    save(tap) {
      this.cargando = true;
      let data = {};
      let data_form = null;
      let url = "/api/tep/viajes/save";

      switch (tap) {
        case "detalles":
          data_form = this.form.detalles;
          break;

        case "pasajeros":
          data_form = this.form.pasajeros;
          break;

        case "presupuesto":
          data_form = this.form.presupuesto;
          break;

        case "fechas":
          data_form = {
            fecha_ini: `${this.form.fechas.fecha_ini} ${this.form.fechas.hora_ini}`,
            fecha_fin: `${this.form.fechas.fecha_fin} ${this.form.fechas.hora_fin}`,
          };
          url = "/api/tep/viajes/saveFechas";
          break;
      }

      if (data_form) {
        data = {
          id: this.id,
          tap,
          data_form,
        };
        let me = this;
        axios
          .post(url, data)
          .then((response) => {
            me.cargando = false;
            if (response.data.msg) {
              this.$swal({
                icon: "success",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (response.data.error) {
              me.$swal({
                icon: "error",
                title: "Ocurrió un error: " + response.data.error.errorInfo[2],
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.init();
          })
          .catch(function(error) {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    // Funciones para detalles
    validaFechasServicioUsuario() {
      if (
        this.form.detalles.fecha_ini_app_user &&
        this.form.detalles.hora_ini_app_user &&
        this.form.detalles.fecha_fin_app_user &&
        this.form.detalles.hora_fin_app_user
      ) {
        const fecha_menor = new Date(
          `${this.form.detalles.fecha_ini_app_user} ${this.form.detalles.hora_ini_app_user}`
        );
        const fecha_mayor = new Date(
          `${this.form.detalles.fecha_fin_app_user} ${this.form.detalles.hora_fin_app_user}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.detalles.fecha_fin_app_user = null;
          this.form.detalles.hora_fin_app_user = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio del servicio del usuario no puede ser mayor a la fecha fin del servicio.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasServicioConductor() {
      if (
        this.form.detalles.fecha_ini_app_conductor &&
        this.form.detalles.hora_ini_app_conductor &&
        this.form.detalles.fecha_fin_app_conductor &&
        this.form.detalles.hora_fin_app_conductor
      ) {
        const fecha_menor = new Date(
          `${this.form.detalles.fecha_ini_app_conductor} ${this.form.detalles.hora_ini_app_conductor}`
        );
        const fecha_mayor = new Date(
          `${this.form.detalles.fecha_fin_app_conductor} ${this.form.detalles.hora_fin_app_conductor}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.detalles.fecha_fin_app_conductor = null;
          this.form.detalles.hora_fin_app_conductor = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio del servicio del conductor no puede ser mayor a la fecha fin del servicio.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    // Funciones para pasajeros
    addFormPasajero() {
      this.verFormPasajeros = true;
      this.formPasajeros = {
        tep_itinerario_id: this.id,
        nombre: null,
        documento: null,
        email: null,
        telefono: null,
        empresa: null,
      };
    },

    deleteFormPasajero() {
      this.verFormPasajeros = false;
    },

    validaFuncionario() {
      this.cargando = true;
      axios
        .get("/api/admin/funcionarios/funcionarioByDoc", {
          params: { no_documento: this.formPasajeros.documento },
        })
        .then((response) => {
          if (response.data.id != undefined) {
            (this.formPasajeros.nombre = `${response.data.nombres} ${response.data.apellidos}`),
              (this.formPasajeros.email = response.data.email),
              (this.formPasajeros.telefono = response.data.celular_principal),
              (this.formPasajeros.empresa = `Frontera`);
          } else {
            this.$swal({
              icon: "error",
              title: `No existe el número de documento..`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
          this.cargando = false;
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    addPasajero() {
      this.verFormPasajeros = false;
      //  Se llena la lista de pasajeros
      this.listasForms.pasajeros.push({
        id: null,
        nombre: this.formPasajeros.nombre,
        documento: this.formPasajeros.documento,
        email: this.formPasajeros.email,
        telefono: this.formPasajeros.telefono,
        empresa: this.formPasajeros.empresa,
      });
      // Se llena el formulario de pasajeros
      this.form.pasajeros.push({
        id: null,
        tep_itinerario_id: this.viaje.itinerario.id,
        nombre: this.formPasajeros.nombre,
        documento: this.formPasajeros.documento,
        email: this.formPasajeros.email,
        telefono: this.formPasajeros.telefono,
        empresa: this.formPasajeros.empresa,
      });
    },

    // Funciones para presupuestos
    async calcularCecoAfeItinerario() {
      this.cecosAfesItin = [];
      let count_porcentaje = 0;
      const length = this.form.presupuesto.length - 1;

      // Deducción de porcentaje
      const deduccion = parseFloat(100) - parseFloat(length);
      this.form.presupuesto.forEach((cecoafe, index) => {
        if (this.form.presupuesto.length == 1) {
          cecoafe.porcentaje = 100;
          this.cecosAfesItin.push(100);
        } else {
          if (parseFloat(cecoafe.porcentaje) == 100 && index == 0) {
            cecoafe.porcentaje -= this.form.presupuesto.length - 1;
            this.cecosAfesItin.push(parseFloat(cecoafe.porcentaje));
          } else if (
            parseFloat(cecoafe.porcentaje) &&
            count_porcentaje + parseFloat(cecoafe.porcentaje) <= deduccion
          ) {
            this.cecosAfesItin.push(parseFloat(cecoafe.porcentaje));
          } else {
            if (parseFloat(cecoafe.porcentaje) >= deduccion) {
              if (index == 0) {
                cecoafe.porcentaje = deduccion;
                this.cecosAfesItin.push(parseFloat(cecoafe.porcentaje));
              } else {
                this.cecosAfesItin.push(1);
                cecoafe.porcentaje = 1;
                let flag = true;
                this.form.presupuesto.forEach((ca) => {
                  if (flag) {
                    if (ca.porcentaje > 1) {
                      flag = false;
                      ca.porcentaje -= 1;
                      count_porcentaje -= 1;
                    }
                  }
                });
              }
            } else if (count_porcentaje < deduccion) {
              this.cecosAfesItin.push(parseFloat(cecoafe.porcentaje));
            } else {
              this.cecosAfesItin.push(1);
              cecoafe.porcentaje = 1;
            }
          }
        }
        count_porcentaje += parseFloat(cecoafe.porcentaje);
      });
      this.total_porcentaje_itin = count_porcentaje;
    },

    async validaPorcentajeCecoAfeItinerario(index) {
      let msg_error = null;
      const porcentaje_inicial = parseFloat(this.cecosAfesItin[index]);
      if (
        this.form.presupuesto[index].porcentaje &&
        this.form.presupuesto[index].porcentaje > 0
      ) {
        await this.calcularCecoAfeItinerario();
        if (this.total_porcentaje_itin > 100) {
          msg_error = {
            title: `Excedio la cantidad de ceco y afe permitida.`,
            text: `El limite del porcentaje es 100% de Ceco o Afe, por favor validar...`,
          };
        }
      } else {
        msg_error = {
          title: `Se debe diligenciar un porcentaje para el AFE o Ceco.`,
          text: `Por favor diligencie el campo...`,
        };
      }

      if (msg_error) {
        this.$swal({
          title: msg_error.title,
          text: msg_error.text,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        this.form.presupuesto[index].porcentaje = parseFloat(
          porcentaje_inicial
        );
      }

      // Se reinicia el array de apoyo del formulario de cecos-afes
      this.cecosAfesItin[index] = parseFloat(
        this.form.presupuesto[index].porcentaje
      );
      await this.calcularCecoAfeItinerario();
    },

    // Funciones para fechas
    validaFechasViaje() {
      if (
        this.form.fechas.fecha_ini &&
        this.form.fechas.hora_ini &&
        this.form.fechas.fecha_fin &&
        this.form.fechas.hora_fin
      ) {
        const fecha_menor = new Date(
          `${this.form.fechas.fecha_ini} ${this.form.fechas.hora_ini}`
        );
        const fecha_mayor = new Date(
          `${this.form.fechas.fecha_fin} ${this.form.fechas.hora_fin}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fechas.fecha_fin = null;
          this.form.fechas.hora_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fechas.hora_fin) {
        if (this.form.fechas.hora_fin >= "20:00:00") {
          this.$swal({
            text:
              "Tenga en cuenta que el recorrido de FRONTERA va hasta las 20:00.!!",
            icon: "info",
            confirmButtonText: "Aceptar!",
          });
        }
      }
    },

    back() {
      return this.$router.replace("/Tep/Viajes");
    },
  },

  async mounted() {
    this.cargando = true;
    await this.init();
    this.cargando = false;
  },
};
</script>
